<template>
  <div class="surface">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="del" @click="back">
        <img src="@/assets/images/retreatBtn.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="@/assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 添加 -->
    <div class="add">
      <el-button type="plain" plain @click="showSurfaceBox">添加信息</el-button>
    </div>
    <!-- 家庭情况 -->
    <div class="content" v-if="type == 1">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appellation"
          label="称谓"
          width="150"
          align="center"
        >
          <!-- appellation -->
          <template slot-scope="scope">
            <span>{{ scope.row.appellation | appellation }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="age"
          label="年龄"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="education"
          label="学历"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.education | filterseducation }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="professional"
          label="职业"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.professional | filtersoccupation }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="professional"
          label="职业"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.professional_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="hobbies"
          label="爱好与特长"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="personality"
          label="性格特点"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="liking"
          label="你对她(他)的喜爱程度"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.liking | filterslove }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 学习经历 -->
    <div class="content" v-if="type == 2">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appellation"
          label="起止时间"
          width="250"
          align="center"
        >
          <template slot-scope="scope">
            <div class="tiemSpan">
              <span>{{ scope.row.start_time }}</span>
              <span style="margin: 0 10px">至</span>
              <span v-if="scope.row.end_time != '1970-01-01 08:00:00'">{{
                scope.row.end_time
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="school_name"
          label="在何校学习"
          width="300"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="position"
          label="担任职务"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 成功与失败 -->
    <div class="content" v-if="type == 3">
      <el-table
        :data="success"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="date_time"
          label="时间"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.date_time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="desc"
          label="成功经历"
          width="800"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content" v-if="type == 3">
      <el-table
        :data="failed"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appellation"
          label="时间"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.date_time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="desc"
          label="失败经历"
          width="800"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 自我评价 -->
    <div class="content" v-if="type == 4">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="interpersonal_relationships"
          label="人际关系"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.interpersonal_relationships | filtersevaluate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="study_result"
          label="学习成绩"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.study_result | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="learning_attitude"
          label="学习态度"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.learning_attitude | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="study_habits"
          label="学习习惯"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.study_habits | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ideological_moral"
          label="思想品德"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.ideological_moral | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="behavior"
          label="行为习惯"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.behavior | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="physical_condition"
          label="身体状况"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.physical_condition | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="self_control"
          label="自控能力"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.self_control | filtersevaluate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 自我评价困惑与烦恼 -->
    <div class="content" v-if="type == 5">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="content"
          label="困惑与烦恼"
          width="1000"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="delArchives(scope.row.id)"
              >删除</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showSurfaceBox(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加/编辑/模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="surfaceBoxTitle"
      :visible.sync="surfaceBox"
      width="50%"
      center
      @close="resetForm"
    >
      <!-- 家庭情况 -->
      <div v-if="type == 1" class="family">
        <el-form
          :model="form1"
          ref="form1"
          :rules="rules"
          label-width="160px"
          size="mini"
        >
          <el-form-item label="称谓" prop="appellation">
            <el-select v-model="form1.appellation">
              <el-option
                :label="item"
                :value="key + 1"
                v-for="(item, key) in appellationArr"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form1.name"></el-input>
          </el-form-item>
          <!-- onkeyup="value = value.replace(/[^\d]/g, '')" -->
          <el-form-item label="年龄" prop="age">
            <el-input
              min="0"
              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
              v-model="form1.age"
              type="number"
              maxlength="3"
            ></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="education">
            <el-select v-model="form1.education">
              <el-option
                :label="item"
                :value="key + 1"
                v-for="(item, key) in seducation"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职业" prop="professional">
            <el-select v-model="form1.professional">
              <el-option
                :label="item"
                :value="key"
                v-for="(item, key) in occupation"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="爱好与特长" prop="hobbies">
            <el-input type="textarea" v-model="form1.hobbies"></el-input>
          </el-form-item>
          <el-form-item label="个性特点" prop="personality">
            <el-input type="textarea" v-model="form1.personality"></el-input>
          </el-form-item>
          <el-form-item label="你对她(他)的喜爱程度" prop="liking">
            <el-radio-group v-model="form1.liking">
              <el-radio
                :label="key + 1"
                v-for="(item, key) in loveArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 学习经历 -->
      <div v-if="type == 2">
        <el-form
          :model="form2"
          ref="form2"
          :rules="rules1"
          label-width="195px"
          size="mini"
        >
          <el-form-item label="起止时间" prop="start_time">
            <!-- <el-date-picker
              v-model="form2.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              unlink-panels="true"
            ></el-date-picker>-->
            <el-date-picker
              v-model="form2.start_time"
              type="date"
              placeholder="选择开始日期"
              value-format="timestamp"
              :picker-options="pickerOptions0"
            ></el-date-picker>
            <span style="margin: 0 10px">至</span>
            <el-date-picker
              v-model="form2.end_time"
              type="date"
              placeholder="选择结束日期"
              value-format="timestamp"
              :picker-options="pickerOptions1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="担任职务" prop="position">
            <el-input v-model="form2.position"></el-input>
          </el-form-item>
          <el-form-item label="在何校学习" prop="school_name">
            <el-input v-model="form2.school_name"></el-input>
          </el-form-item>
          <el-form-item label="对当时所处集体的喜爱程度" prop="liking">
            <el-radio-group v-model="form2.liking">
              <el-radio
                :label="key + 1"
                v-for="(item, key) in loveArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 成功与失败 -->
      <div v-if="type == 3">
        <el-form
          :model="form3"
          ref="form3"
          :rules="rules2"
          label-width="180px"
          size="mini"
        >
          <el-form-item label="时间" prop="date_time">
            <el-date-picker
              value-format="timestamp"
              v-model="form3.date_time"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="类型" prop="class">
            <el-radio-group v-model="form3.class">
              <el-radio :label="1">成功</el-radio>
              <el-radio :label="0">失败</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="经历详情" prop="desc">
            <el-input type="textarea" v-model="form3.desc"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 自我评价 -->
      <div v-if="type == 4">
        <el-form
          :model="form4"
          ref="form4"
          :rules="rules3"
          label-width="180px"
          size="mini"
        >
          <el-form-item label="人际关系" prop="interpersonal_relationships">
            <el-radio-group v-model="form4.interpersonal_relationships">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学习成绩" prop="study_result">
            <el-radio-group v-model="form4.study_result">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学习态度" prop="learning_attitude">
            <el-radio-group v-model="form4.learning_attitude">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学习习惯" prop="study_habits">
            <el-radio-group v-model="form4.study_habits">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="思想品德" prop="ideological_moral">
            <el-radio-group v-model="form4.ideological_moral">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="行为习惯" prop="behavior">
            <el-radio-group v-model="form4.behavior">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身体状况" prop="physical_condition">
            <el-radio-group v-model="form4.physical_condition">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自控能力" prop="self_control">
            <el-radio-group v-model="form4.self_control">
              <el-radio
                :label="key"
                v-for="(item, key) in evaluateArr"
                :key="key"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 自我评价困惑与烦恼 -->
      <div v-if="type == 5">
        <el-form
          :model="form5"
          ref="form5"
          :rules="rules4"
          label-width="180px"
          size="mini"
        >
          <el-form-item label="困惑与烦恼：" prop="content">
            <el-input type="textarea" v-model="form5.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="surfaceBox = false">取 消</el-button>
        <el-button type="primary" @click="addArchives">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getArchives,
  listArchives,
  createdArchives,
  updateArchives,
  deleteArchives,
} from "@/api/information.js";
import { loadArchivesInfo } from "@/api/evaluate.js";
import { log } from "util";
import { async } from "q";
import { setTimeout } from "timers";
import moment from "moment";
export default {
  inject: ["reload"],
  data() {
    return {
      // *******检验*********
      rules: {
        appellation: [
          { required: true, message: "请选择称谓", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        education: [{ required: true, message: "请选择学历", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        professional: [
          { required: true, message: "请选择职业", trigger: "blur" },
        ],
        liking: [
          { required: true, message: "请选择喜爱程度", trigger: "blur" },
        ],
      },
      rules1: {
        start_time: [
          {
            required: true,
            message: "请选择开始时间，结束时间可不填写",
            trigger: "blur",
          },
        ],
        position: [
          { required: true, message: "请输入担任职务", trigger: "blur" },
        ],
        school_name: [
          { required: true, message: "请输入学校姓名", trigger: "blur" },
        ],
        liking: [
          { required: true, message: "请选择喜爱程度", trigger: "blur" },
        ],
      },
      rules2: {
        date_time: [{ required: true, message: "请选择时间", trigger: "blur" }],
        class: [{ required: true, message: "请选择成功与否", trigger: "blur" }],
        desc: [{ required: true, message: "请填写经历", trigger: "blur" }],
      },
      rules3: {
        interpersonal_relationships: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        study_result: [{ required: true, message: "请选择", trigger: "blur" }],
        learning_attitude: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        study_habits: [{ required: true, message: "请选择", trigger: "blur" }],
        ideological_moral: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        behavior: [{ required: true, message: "请选择", trigger: "blur" }],
        physical_condition: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        self_control: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      rules4: {
        content: [
          { required: true, message: "请选择填写内容", trigger: "blur" },
        ],
      },
      // *******************
      tableData: [],
      failed: [],
      success: [],
      uid: 0, //当前用户ID
      type: 0, //当前档案类型
      isSetId: 0, //当前选择行
      isCode: 0, //模态框状态   编辑或添加
      page: 1,
      size: 15,
      archivesData: [],
      occupation: [],
      surfaceBox: false,
      surfaceBoxTitle: "添加信息",
      form1: {
        user_id: 0,
        type: 0,
        appellation: "",
        name: "",
        age: "",
        education: "",
        professional: "",
        hobbies: "",
        personality: "",
        liking: "",
      },
      form2: {
        date: [],
        start_time: "",
        end_time: "",
        school_name: "",
        position: "",
        liking: "",
      },
      form3: {
        date_time: "",
        desc: "",
        class: "",
      },
      form4: {
        self_control: "",
        physical_condition: "",
        behavior: "",
        ideological_moral: "",
        study_habits: "",
        learning_attitude: "",
        study_result: "",
        interpersonal_relationships: "",
      },
      form5: {
        content: "",
      },
      loveArr: ["喜爱", "较喜爱", "无所谓", "不喜爱"],
      seducation: [
        "文盲",
        "小学",
        "初中",
        "高中/中专/技校",
        "大学专科",
        "大学本科",
        "硕士",
        "博士及以上",
      ],
      appellationArr: [
        "父亲",
        "母亲",
        "继父",
        "继母",
        "养父",
        "养母",
        "爷爷",
        "奶奶",
        "姥爷",
        "姥姥",
        "哥哥",
        "姐姐",
        "弟弟",
        "妹妹",
      ],
      evaluateArr: ["很好", "较好", " 一般", "较差", "很差"],
      // 日期
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.form2.end_time != "") {
            return (
              time.getTime() > Date.now() ||
              time.getTime() > this.form2.end_time
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form2.start_time ||
            time.getTime() > Date.now()
          );
        },
      },
    };
  },

  created() {
    //   获取 路由 传递过来的参数
    this.uid = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getListArchives();
    this.loadArchives();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    //获取档案列表
    getListArchives() {
      let data = {
        user_id: this.uid,
        type: this.type,
        page: this.page,
        size: this.size,
      };
      listArchives(data).then((res) => {
        //console.log(res);
        if (this.type == 3) {
          this.failed = res.data.data.failed;
          this.success = res.data.data.success;
        } else {
          this.tableData = res.data.data;
        }
      });
    },
    // 获取档案详情
    getArchivesInfo(obj) {
      getArchives(obj).then((res) => {
        // //console.log(res);
      });
    },
    // 加载自定义档案信息
    loadArchives() {
      loadArchivesInfo().then((res) => {
        this.archivesData = res.data;
        res.data.forEach((v) => {
          if (v.key == "profession") {
            this.occupation = v.content;
          }
        });
        localStorage.setItem("ArchivesInfo", JSON.stringify(this.occupation));
      });
    },
    // 打开模态框
    showSurfaceBox(row) {
      this.surfaceBoxTitle = "添加信息";
      this.isSetId = row.id;
      this.isCode = 1;
      this.surfaceBox = true;
      // 编辑状态渲染
      if (row.id) {
        this.surfaceBoxTitle = "编辑信息";
        this.isCode = 0;
        if (this.type == 1) {
          let {
            appellation,
            name,
            age,
            education,
            professional,
            hobbies,
            personality,
            liking,
          } = row;
          this.form1.appellation = parseInt(appellation);
          this.form1.name = name;
          this.form1.age = age;
          this.form1.education = education;
          this.form1.professional = professional;
          this.form1.hobbies = hobbies;
          this.form1.personality = personality;
          this.form1.liking = liking;
        }
        if (this.type == 2) {
          let { start_time, end_time, school_name, position, liking, id } = row;
          // this.form2.start_time.push(moment(start_time).valueOf());
          // this.form2.end_time.push(moment(end_time).valueOf());
          this.form2.start_time = moment(start_time).valueOf();
          this.form2.end_time = moment(end_time).valueOf();
          this.form2.school_name = school_name;
          this.form2.position = position;
          this.form2.liking = liking;
        }
        if (this.type == 3) {
          let { desc, date_time } = row;
          this.form3.class = row.class;
          this.form3.desc = desc;
          this.form3.date_time = moment(date_time).valueOf();
        }
        if (this.type == 4) {
          let {
            self_control,
            physical_condition,
            behavior,
            ideological_moral,
            study_habits,
            learning_attitude,
            study_result,
            interpersonal_relationships,
          } = row;
          this.form4.self_control = self_control;
          this.form4.physical_condition = physical_condition;
          this.form4.behavior = behavior;
          this.form4.ideological_moral = ideological_moral;
          this.form4.study_habits = study_habits;
          this.form4.learning_attitude = learning_attitude;
          this.form4.study_result = study_result;
          this.form4.interpersonal_relationships = interpersonal_relationships;
        }
        if (this.type == 5) {
          this.form5.content = row.content;
        }
      }
    },
    // 添加 / 修改
    addArchives() {
      this.form1.user_id = this.uid;
      this.form1.type = this.type;
      let newData = {};
      let info = {
        user_id: this.uid,
        type: this.type,
      };
      // 数据处理
      // 家庭情况
      if (this.type == 1) {
        Object.assign(newData, info, this.form1);
        // ***********校验************
        let isCode = true; //控制是否表单验证通过
        this.$refs.form1.validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "温馨提醒，还有内容未填写",
              type: "warning",
            });
            isCode = false;
            return;
          }
        });
        //控制是是否结束请求
        if (!isCode) {
          return;
        }
      }
      if (this.type == 2) {
        // ***********校验************
        let isCode = true; //控制是否表单验证通过
        this.$refs.form2.validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "温馨提醒，还有内容未填写",
              type: "warning",
            });
            isCode = false;
            return;
          }
        });
        //控制是是否结束请求
        if (!isCode) {
          return;
        }
        this.form2.start_time = this.form2.start_time / 1000;
        if (this.form2.end_time == "") {
          this.form2.end_time = 0;
        } else {
          this.form2.end_time = this.form2.end_time / 1000;
        }
        Object.assign(newData, info, this.form2);
      }
      if (this.type == 3) {
        // ***********校验************
        let isCode = true; //控制是否表单验证通过
        this.$refs.form3.validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "温馨提醒，还有内容未填写",
              type: "warning",
            });
            isCode = false;
            return;
          }
        });
        //控制是是否结束请求
        if (!isCode) {
          return;
        }
        this.form3.date_time = this.form3.date_time / 1000;
        Object.assign(newData, info, this.form3);
      }
      if (this.type == 4) {
        // ***********校验************
        let isCode = true; //控制是否表单验证通过
        this.$refs.form4.validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "温馨提醒，还有内容未填写",
              type: "warning",
            });
            isCode = false;
            return;
          }
        });
        //控制是是否结束请求
        if (!isCode) {
          return;
        }
        Object.assign(newData, info, this.form4);
      }
      if (this.type == 5) {
        // ***********校验************
        let isCode = true; //控制是否表单验证通过
        this.$refs.form5.validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "温馨提醒，还有内容未填写",
              type: "warning",
            });
            isCode = false;
            return;
          }
        });
        //控制是是否结束请求
        if (!isCode) {
          return;
        }
        Object.assign(newData, info, this.form5);
      }
      if (this.isCode === 1) {
        console.log(newData);

        createdArchives(newData).then((res) => {
          if (res.code === 400200) {
            this.$message({
              message: "恭喜你，添加成功",
              type: "success",
            });
            this.surfaceBox = false;
          }
          this.getListArchives();
        });
      } else if (this.isCode === 0) {
        newData.id = this.isSetId;
        // delete newData.type;
        updateArchives(newData).then((res) => {
          if (res.code === 400200) {
            this.$message({
              message: "恭喜你，修改成功",
              type: "success",
            });
            this.surfaceBox = false;
          }
          this.getListArchives();
        });
      }
    },
    // 重置模态框
    resetForm() {
      this.form1.appellation = "";
      this.form1.name = "";
      this.form1.education = "";
      this.form1.age = "";
      this.form1.professional = "";
      this.form1.liking = "";
      this.form1.hobbies = "";
      this.form1.personality = "";
      // ------------------
      this.form2.date = [];
      this.form2.start_time = "";
      this.form2.end_time = "";
      this.form2.school_name = "";
      this.form2.position = "";
      this.form2.liking = "";
      // ---------------------
      this.form3.date_time = "";
      this.form3.desc = "";
      this.form3.class = "";
      // ---------------------
      this.form4.interpersonal_relationships = "";
      this.form4.study_result = "";
      this.form4.learning_attitude = "";
      this.form4.study_habits = "";
      this.form4.ideological_moral = "";
      this.form4.behavior = "";
      this.form4.physical_condition = "";
      this.form4.self_control = "";
      // ------------------
      this.form5.content = "";
    },
    // 删除
    delArchives(id) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteArchives({ id, type: this.type }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
            this.getListArchives();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  filters: {
    // 职业
    filtersoccupation(val) {
      localStorage.getItem("ArchivesInfo");
      let arr = JSON.parse(localStorage.getItem("ArchivesInfo"));
      return arr[val];
    },
    // 学历
    filterseducation(val) {
      let arr = [
        "文盲",
        "小学",
        "初中",
        "高中/中专/技校",
        "大学专科",
        "大学本科",
        "硕士",
        "博士及以上",
      ];
      return arr[val - 1];
    },
    // 喜爱
    filterslove(val) {
      let arr = ["喜爱", "较喜爱", "无所谓", "不喜爱"];
      return arr[val - 1];
    },
    // 态度
    filtersevaluate(val) {
      let arr = ["很好", "较好", " 一般", "较差", "很差"];
      return arr[val];
    },
    appellation(val) {
      let appellationArr = [
        "父亲",
        "母亲",
        "继父",
        "继母",
        "养父",
        "养母",
        "爷爷",
        "奶奶",
        "姥爷",
        "姥姥",
        "哥哥",
        "姐姐",
        "弟弟",
        "妹妹",
      ];
      return appellationArr[val - 1];
    },
  },
};
</script>

<style lang="less">
.surface {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   添加
  .add {
    margin-top: 20px;
    height: 55px;
    background-color: rgb(#d9f2f7);
    display: flex;
    align-items: center;
    padding-left: 10px;
    .el-divider--vertical {
      height: 2em;
    }
    .el-button {
      background: transparent;
      color: rgb(#044d5a);
      font-size: 15px;
      border: 1px solid rgb(182, 226, 235);
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .el-input {
      width: 150px;
      margin-right: 5px;
    }
    .el-input__inner {
      width: 150px;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    // min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    .family {
      .el-form-item:nth-child(1) .el-input__inner {
        width: 150px;
        padding-right: 0;
      }
      .el-form-item:nth-child(2) .el-input__inner {
        width: 150px;
        padding-right: 0;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        width: 150px;
        padding-right: 0;
      }
    }
  }
  .tiemSpan {
    text-align: left;
  }
}
</style>